import { render, staticRenderFns } from "./NotificationEmail.vue?vue&type=template&id=736a9700&scoped=true"
import script from "./NotificationEmail.vue?vue&type=script&lang=js"
export * from "./NotificationEmail.vue?vue&type=script&lang=js"
import style0 from "./NotificationEmail.vue?vue&type=style&index=0&id=736a9700&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "736a9700",
  null
  
)

export default component.exports